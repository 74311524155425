import {Component, OnDestroy, OnInit} from '@angular/core';

import {AlertController, NavController, Platform, ToastController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {environment} from '../environments/environment';
import {Router, NavigationEnd} from '@angular/router';
import {SessionService} from './session.service';
import {EventsService} from './events.service';
import {MenuType} from './enums/menu-types';
import {TranslateService} from '@ngx-translate/core';
import {appConfig} from './appConfig';
import {AuthService} from './auth.service';
import {RoleType} from './enums/role-types';
import {UserService} from './user.service';
import {DistributorService} from './distributor.service';
import {ProfileService} from './profile.service';
import {GroupService} from './group.service';
// import {SwUpdate} from '@angular/service-worker';
import {HttpClient, HttpParams} from '@angular/common/http';
import {firebaseConfig} from './credentials';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public selectedIndex: MenuType = MenuType.PROFILES;
  swupdate = false;
  promptEvent = false;
  public RoleType: typeof RoleType = RoleType;
  public specialColors = '';
  public powerLinkDistributor = '5ptCifZKOMQk4A8H3VqRCEHeHfB2';

  public appPages = [
    {
      title: 'Edit',
      url: '/edit',
      icon: 'create'
    },
    {
      title: 'View',
      url: '/view',
      icon: 'eye'
    },
    {
      title: 'search',
      url: '/search',
      icon: 'settings'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  public MenuType: typeof MenuType = MenuType;
  public distributor;
  public viewState = false;
  public isGroupEditPage = false;
  public isProfileEditPage = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private navController: NavController,
    public session: SessionService,
    private events: EventsService,
    private translate: TranslateService,
    private alertController: AlertController,
    public authService: AuthService,
    public userService: UserService,
    public profileService: ProfileService,
    public groupService: GroupService,
    public distributorService: DistributorService,
    private toastController: ToastController,
    public http: HttpClient,
    //updates: SwUpdate
  ) {
    // updates.available.subscribe(event => {
    //   this.swupdate = true;
    //   if (confirm('do update new version?')) {
    //     window.location.reload();
    //   }
    // });
    this.platform.ready().then(() => {
      const myBody = document.getElementsByTagName('body')[0];
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        myBody.classList.add('dark_theme');
        myBody.classList.remove('light_theme');

      } else {
        myBody.classList.add('light_theme');
        myBody.classList.remove('dark_theme');

      }
    });
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
    this.viewState = location.pathname.includes('/v/');
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.start();
    });
  }

  ngOnInit() {
    const saveGLang = localStorage.getItem('globalLang');
    if ( saveGLang ) {
      this.changeLang(saveGLang);
    } else {
      this.changeLang(appConfig.settings.default.lang);
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd ) {
        if ( event.url.includes('/groups') ) {
          this.isGroupEditPage = true;
          this.isProfileEditPage = false;
        } else if ( event.url.includes('/profiles') ) {
          this.isProfileEditPage = true;
          this.isGroupEditPage = false;
        } else if ( event.url.includes('/edit') ) {
          setTimeout(() => {
            if (document.getElementById('breadcrumbTopper')) {
              if ( document.getElementById('breadcrumbTopper').getAttribute('data-group') === 'true' ) {
                this.isProfileEditPage = false;
                this.isGroupEditPage = true;
              } else {
                this.isProfileEditPage = true;
                this.isGroupEditPage = false;
              }
            }
          }, 500);
        } else {
          this.isProfileEditPage = false;
          this.isGroupEditPage = false;
        }
      }
    });
    this.events.subscribe('menu', (data) => {
      if (data.target === MenuType.PROFILES) {
        this.selectedIndex = MenuType.PROFILES;
      } else if (data.target === MenuType.GROUPS) {
        this.selectedIndex = MenuType.GROUPS;
      } else if (data.target === MenuType.DISTRIBUTORS) {
        this.distributor = this.session.getDistributor();
      }
    });
  }

  ngOnDestroy() {
    this.events.destroy('menu');
  }

  //@@@ codja
  public findGetParameterScope( name ) {
    let result = null;
    let tmp = [];
    const items = location.search.substr(1).split('&');
    for (let index = 0; index < items.length; index++) {
      tmp = items[index].split('=');
      if (tmp[0] === name) {
        result = decodeURIComponent(tmp[1]);
      }
    }
    return result;
  }

  //@@@ codja
  async TopScopecheckForReferrer() {
    const ref = this.findGetParameterScope( 'ref' );
    if ( ref ) {
      this.distributorService.getDistributorByEmail( ref ).then((res: any) => {
        if ( res ) {
          if (res._id === this.powerLinkDistributor) {
            this.specialColors = 'powerLinkDistributor';
          }
        }
      });
    } else {
      if ( location.pathname.includes('/v') ) {
        const fv = this;
        const parts = window.location.pathname.split('/v/');
        if (parts.length > 0 && parts[0] === '') {
          fv.getEntityScope(parts[1]).then( async (en) => {
            if (en) {
              if ( en['distributor'] ) {
                if (en['distributor'].id === fv.specialColors) {
                  fv.specialColors = 'powerLinkDistributor';
                }
              }
            }
          });
        }
      }
    }
  }

  async getEntityScope(key) {
    return this.http.get(`${this.getDatabaseUrlScope()}/api/v1/entity-view/${key}`).toPromise().then(entity => {
      return entity;
    });
  }

  protected getDatabaseUrlScope() {
    if (appConfig.settings.localDB) {
      return appConfig.settings.localDBUrl;
    } else {
      return firebaseConfig.restURL + '/rest';
    }
  }

  start() {
    const fv = this;
    firebase.initializeApp(environment.firebaseConfig);
    firebase.auth().onAuthStateChanged(async (authUser) => {
      fv.TopScopecheckForReferrer();
      if (authUser) {
        console.log('logged in');
        // TODO: get distributor from path or from user
        const user = await this.userService.getUser$(authUser.uid);
        try {
          if (user) {
            this.session.setActiveUser(user);
            if ( this.powerLinkDistributor === user.distributor.id ) {
              this.specialColors = 'powerLinkDistributor';
            }
            let distributor = await this.distributorService.getDistributor$(user.distributor.id);
            if ( user.type === 'ADMIN' ) {
              distributor = this.session.getDistributor();
            }
            if (!distributor && appConfig.settings.isDemo !== true) {
              console.error(`distributor not found for user ${authUser.uid}`);
              this.gotoLogin(true);
              return;
            }
            if (appConfig.settings.isDemo) {
              this.distributor = this.session.getActiveDistributor();
            } else {
              this.distributor = distributor;
              this.session.setDistributor(distributor);
            }
            if (user.lang && !localStorage.getItem('globalLang')) {
              this.translate.setDefaultLang(user.lang);
              this.translate.use(user.lang);
            }
          } else {
            const err = `cannot find user ${authUser.uid}. navigate to login`;
            console.error(err);
            const toast = await this.toastController.create({
              message: err,
              duration: 2000,
              position: 'middle'
            });
            toast.present();
            this.gotoLogin(true);
          }
        } catch (err) {
          console.error(err);
          const toast = await this.toastController.create({
            message: err,
            duration: 2000,
            position: 'middle'
          });
          toast.present();
          this.gotoLogin(true);
        }
      } else {
        if (location.pathname.includes('/confirmed') || location.pathname.includes('/bdo') || location.pathname.includes('/register') || location.pathname.includes('/v') || location.pathname.includes('/reset') || location.pathname.includes('/removesubscriber')) {
          return;
        } else {
          console.log('not logged in. navigate to login');
          this.gotoLogin(false);
        }
      }
    });
  }

  async gotoLogin(forceLogout: boolean = false) {
    if (forceLogout) {
      await this.logout();
      return;
    }
    this.router.navigateByUrl('/login');
  }

  public gotoSettings(event) {
    // this.router.navigateByUrl('/admin');
    this.navController.navigateRoot('/settings');
  }

  public gotoImports(event) {
    // this.router.navigateByUrl('/admin');
    this.navController.navigateRoot('/imports');
  }

  public gotoUsers(event) {
    // this.router.navigateByUrl('/admin');
    this.navController.navigateRoot('/users');
  }

  public gotoProfiles(event) {
    // this.router.navigateByUrl('/admin');
    this.navController.navigateRoot('/profiles');
  }

  public gotoGroups(event) {
    // this.router.navigateByUrl('/admin');
    this.navController.navigateRoot('/groups');
  }

  public gotoDistributors(event) {
    this.navController.navigateRoot('/distributors');
  }

  public gotoReports(event) {
    this.navController.navigateRoot('/reports');
  }
  public gotoSubscriptionReports(event) {
    this.navController.navigateRoot('/reports-subscription');
  }

  public gotoHelp(event) {
    this.navController.navigateRoot('/help');
  }

  public saveCardPage(event) {
    this.navController.navigateRoot('/save-card');
  }

  public noInternet(event) {
    this.navController.navigateRoot('/offline-mode');
  }

  logout() {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      this.session.removeUser();
      console.log('logged out!');
      // this.router.navigateByUrl('/login');
      if (this.router.url === '/login') {
        location.reload();
      } else {
        this.navController.navigateRoot('/login');
      }
    }).catch((error) => {
      // An error happened.
      console.error(error);
    });
  }

  login() {
    this.router.navigateByUrl('/login');
  }

  async lang() {

    const langs = appConfig.settings.langs;

    const alertInputs = [];
    for (const item of langs) {
      alertInputs.push({
        name: 'radio1',
        type: 'radio',
        label: item.label,
        value: item.lang,
        checked: this.session.appLang === item.lang
      });
    }

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Select Language',
      inputs: alertInputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (value) => {
            this.changeLang(value);
          }
        }
      ]
    });

    await alert.present();

  }

  help() {

  }

  getDistributorLogo() {
    if (this.distributor?.logo) {
      return this.distributor.logo;
    }
    return '/assets/images/default-logo.png';
  }

  changeLang(value) {
    const fv = this;
    fv.session.appLang = value;
    localStorage.setItem('globalLang', this.session.appLang);
    if (value === 'he' || value === 'ar') {
      fv.session.appDir = 'rtl';
      fv.translate.use(value);
      const walkme = document.getElementById('walkMePopupData');
      if (walkme) {
        walkme.style.left = 'auto';
        walkme.style.right = '300px';
      }
      setTimeout(() => {
        const el = document.querySelector('html');
        document.body.classList.add('rtl');
        el['style'].setProperty('direction', 'rtl');
        el.setAttribute('dir', 'rtl');
        el['style'].setProperty('--custom-family-font', 'Rubik', 'important');
        const cs = document.querySelector('div.custom-menu-label');
        if (cs) {
          cs['style'].setProperty('font-family', 'Rubik', 'important');
        }
      }, 1500);
    } else {
      fv.session.appDir = 'ltr';
      document.body.classList.remove('rtl');
      fv.translate.use(value);
      const el = document.querySelector('html');
      el['style'].setProperty('direction', 'ltr');
      el.setAttribute('dir', 'ltr');
      el['style'].setProperty('--custom-family-font', 'Montserrat, Rubik', 'important');
      const cs2 = document.querySelector('div.custom-menu-label');
      if (cs2) {
        cs2['style'].setProperty('font-family', 'Montserrat, Rubik', 'important');
      }
    }
  }

}
