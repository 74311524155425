import {Injectable} from '@angular/core';
import {DatabaseService} from './database.service';
import {HttpClient, HttpParams} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ProfileService extends DatabaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  async setVisitProfileStats(profileEntityId: string, parentEntityId: string) {
    const httpParams = new HttpParams();
    const httpOptions = await this.getHttpOptions(httpParams);
    return this.http.get(`${this.getDatabaseUrl()}/api/setVisitProfileStats/${profileEntityId}/${parentEntityId}`, httpOptions).toPromise().then(() => {
      return Promise.resolve();
    });
  }

  async getDistributorByName(name: string) {
    return this.http.get(`${this.getDatabaseUrl()}/api/distributorByName/${name}`).toPromise().then((distributor) => {
      return Promise.resolve(distributor);
    });
  }

  async setVisitProfileFromQR(profileEntityId: string, parentEntityId: string) {
    return this.http.get(`${this.getDatabaseUrl()}/api/setVisitProfileFromQR/${profileEntityId}/${parentEntityId}`).toPromise().then(() => {
      return Promise.resolve();
    });
  }

  async getProfile$(profileId: string) {
    return this.get$(profileId);
  }

  async exportProfiles(filter: any, distribiutor: string) {
    return this.exportEntities('PROFILE', filter, distribiutor);
  }

  async getProfileByUserIdAndLang$(distributor: string, userId: string, lang: string) {
    const filter = {
      'userId': userId,
      'lang': lang
    };
    const profiles = await this.getProfiles$(distributor, filter);
    if (profiles && profiles.length > 0) {
      return profiles[0];
    }
  }


  async createProfile$(distributor: string, profile: any) {
    return this.save$(distributor, profile);
  }


  // getId() {
  //   return super.getId('profiles');
  // }

  async getProfiles$(distributor: string, filter: any): Promise<any> {
    if (!filter) {
      filter = {};
    }
    filter['type'] = 'PROFILE';

    return this.getEntities$(distributor, filter, null, 'profiles', 'profile');
  }


  async getProfileLangs$(distributor: string, userId: string): Promise<any> {
    return this.getEntityLangs$(distributor, userId, null, null, 'profile', 'profile');
  }

  async getProfilesByUserId$(distributor: string, userId: string) {
    const filter = {
      'userId': userId
    };

    return this.getEntities$(distributor, filter, null, 'users', 'user');
  }


  getPath() {
    return 'profile';
  }

  getLabel() {
    return 'profile';
  }

  getId() {
    return super.getId('profiles');
  }

  async save$(distributor: any, entity: any) {
    const name = `${entity.personal.firstName} ${entity.personal.lastName}`;
    entity['personal'].name = name;
    return super.save$(distributor, entity);
  }
}
