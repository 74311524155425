import {Injectable} from '@angular/core';
import {appConfig} from './appConfig';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  authenticated = false;
  user: any;
  distributor: any;
  cardDir = 'ltr';
  cardLang = 'en';
  appDir = 'ltr';
  appLang = 'en';
  pageCounterId = 1;

  constructor(private router: Router) {
      this.distributor = this.defaultDistributor();
  }

  //@@@ codja
  public defaultDistributor() {
    const localD = localStorage.getItem('selected_distributor');
    if ( localD ) {
      return JSON.parse( localD );
    } else {
      if ( this.user ) {
        if ( this.user.type === 'ADMIN' ) {
          return {
            _id: 'Cmycard',
            name: 'Cmycard',
            logo: '/assets/images/default-logo.png',
            email: '',
            password: ''
          };
        }
      } else {
        return this.distributor;
      }
    }
  }

  menu_visible() {
    const mixid = localStorage.getItem('mixId');
    if ( mixid ) {
      return true;
    }
    return this.authenticated;
  }

  isAuthenticated() {
    // return appConfig.settings.isDemo || this.authenticated;
    return this.authenticated;
  }

  setActiveUser(user: any) {
    this.user = user;
    this.setAppLang(this.user.lang);
    this.setDirection();
    this.authenticated = true;
  }

  setAppLang(lang: string) {
    const saveGLang = localStorage.getItem('globalLang');
    if ( saveGLang && !lang ) {
      this.appLang = saveGLang;
    } else {
      this.appLang = lang;
    }
  }

  getActiveUser(): any {
    return this.user;
  }

  removeUser() {
    this.user = undefined;
    this.authenticated = false;
  }

  setActiveDistributor(distributor: any) {
    this.distributor = distributor;
  }

  getActiveDistributor(): any {
    return this.distributor;
  }

  removeDistributor() {
    this.distributor = undefined;
  }

  getDistributorId() {
    if ( !this.distributor ) {
      this.distributor = this.user.distributor;
    }
    if (this.distributor.id) {
      return this.distributor.id;
    }
    return this.distributor._id;
  }

  getDistributor() {
    return this.distributor;
  }

  setDistributor(distributor: any, bool: boolean = false) {
    this.distributor = distributor;
    if ( bool === true ) {
      localStorage.setItem('selected_distributor', JSON.stringify(distributor) );
    }
  }

  getCurrentAppLang() {
    const saveGLang = localStorage.getItem('globalLang');
    if ( saveGLang ) {
      this.appLang = saveGLang;
    }
    return this.appLang;
  }

  getNextUniquePageId() {
    return `generated-page-id-${this.pageCounterId++}`;
  }

  setDirection() {
    if (this.appLang === 'he' || this.appLang === 'ar') {
      this.appDir = 'rtl';
      const el = document.querySelector('html');
      el['style'].setProperty('direction', 'rtl');
      el.setAttribute('dir', 'rtl');
      // el['style'].setProperty('--ion-font-family', 'Rubik');
      el['style'].setProperty('--custom-family-font', 'Rubik', 'important');
      if (document.querySelector('div.custom-menu-label')) {
        document.querySelector('div.custom-menu-label')['style'].setProperty('font-family', 'Rubik', 'important');
      }
    } else {
      this.appDir = 'ltr';
      const el = document.querySelector('html');
      el['style'].setProperty('direction', 'ltr');
      el.setAttribute('dir', 'ltr');
      // el['style'].setProperty('--ion-font-family', 'Montserrat');
      el['style'].setProperty('--custom-family-font', 'Montserrat, Rubik', 'important');
      if (document.querySelector('div.custom-menu-label')) {
        document.querySelector('div.custom-menu-label')['style'].setProperty('font-family', 'Montserrat, Rubik', 'important');
      }
    }
  }
}
