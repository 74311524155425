export const firebaseConfig = {
  apiKey: 'AIzaSyBpzi85woIVm_vdMlBRFL-cXubNl3kD2GI',
  //authDomain: 'cmycard.website',
  authDomain: 'cmycard.website',
  databaseURL: 'https://newqrlead3-c161c.firebaseio.com',
  projectId: 'newqrlead3-c161c',
  storageBucket: 'newqrlead3-c161c.appspot.com',
  messagingSenderId: '302747295270',
  appId: '1:302747295270:web:6777c2f36e0d1bdfe050e7',
  measurementId: 'G-J8T9TM237K',
  restURL: 'https://europe-west3-newqrlead3-c161c.cloudfunctions.net'
};
